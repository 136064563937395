import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import Container from "react-bootstrap/Container"

const MentorshipSection1Background = styled.div`
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.gray_dark};
  font: 20px/28px Roboto;
  @media (max-width: 767px) {
    min-height: 0;
    overflow: hidden;
  }
  @media (min-width: 1024px) {
    margin-top: 85px;
  }
`

const MentorshipSection1Title = styled.div`
  font: 58px/62px Abril Fatface;
  margin-bottom: 35px;
  min-height: 132px;
  max-width: 964px;
  color: ${props => props.theme.colors.gray_dark};
  @media (max-width: 1199px) {
    font: 56px/60px Abril Fatface;
  }
  @media (max-width: 767px) {
    font: 2.5rem/3rem Abril Fatface;
    margin-bottom: 17.5px;
  }
`

const SectionItem = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 964px;
  display: flex;
  flex-direction: column;
  text-align: center;
  @media (max-width: 767px) {
    margin-top: 0;
    margin-bottom: 0px;
  }
  @media (min-width: 768px) {
    margin-bottom: 15px;
  }
`

const StatsSection = styled(Row)`
  background-color: ${props => props.theme.colors.accent_yellow90};
  padding-top: 60px;
  padding-bottom: 60px;
  margin-top: 50px;
  justify-content: center;
  @media (max-width: 767px) {
    margin-top: 20px;
  }
`

const StatsTitle = styled(Col)`
  color: ${props => props.theme.colors.primary_violet};
  font: 58px/60px Abril Fatface;
  margin-bottom: 10px;
  @media (max-width: 991px) {
    margin-top: 15px;
  }
`

const StatsSubTitle = styled(Col)`
  color: ${props => props.theme.colors.gray_dark};
  font: 20px/22px Roboto;
`

const ButtonFill1 = styled(Button)`
  height: fit-content;
  align-self: center;
  border-radius: 4px;
  color: ${props =>
    props.theme.buttons.primary_button_violet.normal.color} !important;
  background-color: ${props =>
    props.theme.buttons.primary_button_violet.normal
      .background_color} !important;
  border-color: ${props =>
    props.theme.buttons.primary_button_violet.normal.border_color} !important;
  font: 22px/22px Roboto;
  width: fit-content;
  padding: 16px 30px 16px 30px;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props =>
      props.theme.buttons.primary_button_violet.hovered.color} !important;
    background-color: ${props =>
      props.theme.buttons.primary_button_violet.hovered
        .background_color} !important;
    border-color: ${props =>
      props.theme.buttons.primary_button_violet.hovered
        .border_color} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.colors.gray_shadow} !important;
    @media (min-width: 1199px) {
      margin-top: -3px;
      margin-bottom: 3px;
    }
  }
  &:focus,
  &.focus,
  &:active,
  &.active {
    color: ${props =>
      props.theme.buttons.primary_button_violet.pressed.color} !important;
    background-color: ${props =>
      props.theme.buttons.primary_button_violet.pressed
        .background_color} !important;
    border-color: ${props =>
      props.theme.buttons.primary_button_violet.pressed
        .border_color} !important;
    box-shadow: none !important;
    @media (min-width: 1199px) {
      margin-top: -3px;
      margin-bottom: 3px;
    }
  }
  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 20px;
    font: 22px/22px Roboto;
  }
`

const ButtonOutline1 = styled(Button)`
  height: fit-content;
  align-self: center;
  border-radius: 4px;
  color: ${props =>
    props.theme.buttons.secondary_big_button_violet.normal.color} !important;
  background-color: ${props =>
    props.theme.buttons.secondary_big_button_violet.normal
      .background_color} !important;
  border-color: ${props =>
    props.theme.buttons.secondary_big_button_violet.normal
      .border_color} !important;
  font: 22px/22px Roboto;
  width: fit-content;
  padding: 16px 30px 16px 30px;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props =>
      props.theme.buttons.secondary_big_button_violet.hovered.color} !important;
    background-color: ${props =>
      props.theme.buttons.secondary_big_button_violet.hovered
        .background_color} !important;
    border-color: ${props =>
      props.theme.buttons.secondary_big_button_violet.hovered
        .border_color} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.colors.gray_shadow} !important;
    @media (min-width: 1199px) {
      margin-top: -3px;
      margin-bottom: 3px;
    }
  }
  &:focus,
  &.focus,
  &:active,
  &.active {
    color: ${props =>
      props.theme.buttons.secondary_big_button_violet.pressed.color} !important;
    background-color: ${props =>
      props.theme.buttons.secondary_big_button_violet.pressed
        .background_color} !important;
    border-color: ${props =>
      props.theme.buttons.secondary_big_button_violet.pressed
        .border_color} !important;
    box-shadow: none !important;
    @media (min-width: 1199px) {
      margin-top: -3px;
      margin-bottom: 3px;
    }
  }
  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 20px;
    font: 22px/22px Roboto;
  }
`

const ButtonRow = styled(Row)`
  justify-content: center;
  @media (max-width: 767px) {
    flex-direction: column !important;
  }
`

const ButtonCol = styled(Col)`
  @media (min-width: 768px) {
    max-width: fit-content;
  }
  @media (max-width: 767px) {
    max-width: 100%;
  }
`

export default function MentorshipSection1(props) {
  return (
    <MentorshipSection1Background>
      <Container
        css={css`
          display: block;
        `}
      >
        <SectionItem>
          <MentorshipSection1Title>
            1:1 mentorship to supercharge your career growth
          </MentorshipSection1Title>
          <p>
            Sign up for a personalised 1:1 mentorship and get access to our
            mentors’ professional knowledge and network to help you through
            every stage of your career.
          </p>
          <ButtonRow>
            <ButtonCol>
              <ButtonFill1 href={`/Themed Mentorship Packages.pdf`}>
                Get mentorship topics ideas!
              </ButtonFill1>
            </ButtonCol>
            <ButtonCol>
              <ButtonOutline1 href={`/the-ladybosses`}>
                Get to know the Mentors
              </ButtonOutline1>
            </ButtonCol>
          </ButtonRow>
          <StatsSection>
            <Col xs="12" md="auto" lg="3">
              <StatsTitle>295+</StatsTitle>
              <StatsSubTitle>Mentors ready to guide you</StatsSubTitle>
            </Col>
            <Col xs="12" md="auto" lg="3">
              <StatsTitle>955+</StatsTitle>
              <StatsSubTitle>Mentees joined</StatsSubTitle>
            </Col>
            <Col xs="12" md="auto" lg="3">
              <StatsTitle>3.100+</StatsTitle>
              <StatsSubTitle>Mentorship sessions booked</StatsSubTitle>
            </Col>
            <Col xs="12" md="auto" lg="3">
              <StatsTitle
                css={css`
                  max-width: 180px;
                  display: inline-grid;
                `}
              >
                <Row>
                  <Col>4</Col>{" "}
                  <Col
                    css={css`
                      font: 29px/30px Abril Fatface;
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                    `}
                  >
                    <Row>Out</Row> <Row>of</Row>
                  </Col>
                  <Col>5</Col>
                </Row>
              </StatsTitle>
              <StatsSubTitle>
                mentees find their mentors’ advice actionable
              </StatsSubTitle>
            </Col>
          </StatsSection>
        </SectionItem>
      </Container>
    </MentorshipSection1Background>
  )
}
