import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import Container from "react-bootstrap/Container";
import { OutboundLink } from "gatsby-plugin-google-analytics";


const SignupForMentorshipSection6Background = styled.div`
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.gray_dark};
  font: 20px/28px Roboto;
  @media(max-width:767px) {
    min-height: 0;
    margin-bottom: 65px;
    margin-top: 55px;
    overflow:hidden;
  }
  @media(max-width:991px){
    padding-top: 50px;
    padding-bottom:50px;
  }
  @media(min-width:992px){
    padding-top: 120px;
    padding-bottom:100px;
  }
`

const MentorshipSection1Title = styled.div`
  font: 58px/62px Abril Fatface;
  margin-bottom:35px;
  min-height: 132px;
  color: ${props => props.theme.colors.gray_dark};
  @media(max-width:1199px){
    font: 56px/60px Abril Fatface;
  }
  @media(max-width:767px){
    font: 2.5rem/3rem Abril Fatface;
    margin-bottom:17.5px;
  }
`

const SectionItem = styled.div`
  margin-left: auto;
  margin-right:auto;
  max-width: 964px;
  display:flex;
  flex-direction:column;
  text-align:center;
  @media(max-width:767px){
    margin-top:0;
    margin-bottom:0px;
  }
  @media(min-width:768px){
    margin-bottom: 15px;
  }
`

const ButtonFill1 = styled(Button)`
  height: 60px;
  color: ${props => props.theme.buttons.primary_button_violet.normal.color} !important;
  background-color: ${props => props.theme.buttons.primary_button_violet.normal.background_color} !important;
  border-color: ${props => props.theme.buttons.primary_button_violet.normal.border_color} !important;
  font: 22px/22px Roboto;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props => props.theme.buttons.primary_button_violet.hovered.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_violet.hovered.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_violet.hovered.border_color} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.buttons.primary_button_violet.hovered.shadow} !important;
    @media(min-width:1199px){
      margin-top:-3px;
      margin-bottom:3px;
    }
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.buttons.primary_button_violet.pressed.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_violet.pressed.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_violet.pressed.border_color} !important;
    box-shadow: none !important;
    @media(min-width:1199px){
      margin-top:-3px;
      margin-bottom:3px;
    }
  }
  @media(max-width:921px){
    width:100%;
    margin-bottom:20px;
  }
  @media(min-width:922px){
    margin-right: 26px;
  }
  @media(max-width:767px){
    width:100%;
  }
`

const TopicsLinkText = styled.a`
  color: ${props=>props.theme.colors.primary_violet};
  font-weight:bold;
  text-decoration-line: underline;
`


export default function SignUpForMentorshipSection6() {
  return (
  <SignupForMentorshipSection6Background>
      <Container css={css`display:block;`}>
          <SectionItem>
                <MentorshipSection1Title>
                    Not sure how to plan your mentorship? Choose one of these topics!
                </MentorshipSection1Title>
              <p><b>We’ve gathered the very best results from our mentorships</b> and come up with the ideal Mentorship Topics & Packages to guide you in your growth journey!
                <br/>
                
                <TopicsLinkText href="/Themed Mentorship Packages.pdf"
                >Get the full information here (PDF).</TopicsLinkText>
                </p>
              <Row css={css`margin-top:40px;`}>
                <Col xs="12">
                <OutboundLink href="https://bit.ly/womenworksmenteesignupnew">
                  <ButtonFill1>
                    Sign up for 1:1 Mentorship now
                  </ButtonFill1>
                </OutboundLink>
              </Col>
            </Row>
          </SectionItem>
      </Container>
  </SignupForMentorshipSection6Background>
  )
}